var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"max-width":"800px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('profile-image',{model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Navn","hide-details":!_vm.hasUserRole('ANSATT')},model:{value:(_vm.person.fulltNavn),callback:function ($$v) {_vm.$set(_vm.person, "fulltNavn", $$v)},expression:"person.fulltNavn"}})],1),(_vm.hasRole('BRUKER'))?_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Nr","hide-details":!_vm.hasUserRole('ANSATT')},model:{value:(_vm.person.lopenummer),callback:function ($$v) {_vm.$set(_vm.person, "lopenummer", $$v)},expression:"person.lopenummer"}})],1):_vm._e(),(_vm.hasUserRole('ANSATT'))?[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":"Roller"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-10px"},attrs:{"outlined":""}},on),[_vm._v("Endre ")])]}}],null,false,328133534)},[_c('v-list',[_vm._l((_vm.roller),function(rolle,i){return [(!rolle.foreldre)?_c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.changeRole(rolle.id)}}},[(!_vm.hasRole(rolle.id))?_c('v-list-item-title',[_vm._v("Legg til som "+_vm._s(rolle.navn.toLowerCase())+" ")]):_c('v-list-item-title',[_vm._v(" Fjern som "+_vm._s(rolle.navn.toLowerCase())+" ")])],1):_vm._e()]})],2)],1)]},proxy:true}],null,false,3585050288),model:{value:(_vm.rolleStreng),callback:function ($$v) {_vm.rolleStreng=$$v},expression:"rolleStreng"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":"Tjenester"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-10px"},attrs:{"outlined":""}},on),[_vm._v("Endre ")])]}}],null,false,328133534)},[_c('v-list',[_vm._l((_vm.tjenesteListe),function(rolle,i){return [_c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.changeRole(rolle.id)}}},[(!_vm.hasRole(rolle.id))?_c('v-list-item-title',[_vm._v("Legg til i "+_vm._s(rolle.navn.toLowerCase())+" ")]):_c('v-list-item-title',[_vm._v(" Fjern i "+_vm._s(rolle.navn.toLowerCase())+" ")])],1)]})],2)],1)]},proxy:true}],null,false,2747043622),model:{value:(_vm.tjenesteStreng),callback:function ($$v) {_vm.tjenesteStreng=$$v},expression:"tjenesteStreng"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.person.userId == null)?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.createLogin}},[_vm._v("Opprett konto")]):_c('v-chip',{attrs:{"close":""},on:{"click:close":_vm.deleteLogin}},[_vm._v("Brukernavn: "+_vm._s(_vm.person.epost))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('status-field',{on:{"updated":_vm.updated},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.person.samtykke && _vm.person.samtykke.samtykket && _vm.person.status == 'SLUTTET')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Slett "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down")])],1)]}}],null,false,1867367902)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteUser(true)}}},[_c('v-list-item-title',[_vm._v("Slett og ta vare på kontaktinfomaskjon")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteUser(false)}}},[_c('v-list-item-title',[_vm._v("Slett")])],1)],1)],1):(_vm.person.status == 'SLUTTET')?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.deleteUser(false)}}},[_vm._v("Slett")]):_vm._e()],1)]:[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Epost","hide-details":""},model:{value:(_vm.person.epost),callback:function ($$v) {_vm.$set(_vm.person, "epost", $$v)},expression:"person.epost"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Mobil","hide-details":""},model:{value:(_vm.person.mobil),callback:function ($$v) {_vm.$set(_vm.person, "mobil", $$v)},expression:"person.mobil"}})],1)]],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }