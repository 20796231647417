<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="4">
                        <profile-image v-model="person"></profile-image>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-row>
                            <v-col cols="7">
                                <v-text-field v-model="person.fulltNavn" readonly label="Navn" :hide-details="!hasUserRole('ANSATT')"></v-text-field>
                            </v-col>
                            <v-col cols="3" v-if="hasRole('BRUKER')">
                                <v-text-field v-model="person.lopenummer" readonly label="Nr" :hide-details="!hasUserRole('ANSATT')"></v-text-field>
                            </v-col>
                            <template v-if="hasUserRole('ANSATT')">
                                <v-col cols="10">
                                    <v-text-field dense v-model="rolleStreng" readonly label="Roller">
                                        <template v-slot:append-outer>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined v-on="on" style="margin-top: -10px">Endre </v-btn>
                                                </template>
                                                <v-list>
                                                    <template v-for="(rolle, i) in roller">
                                                        <v-list-item :key="i" v-if="!rolle.foreldre" v-on:click="changeRole(rolle.id)" link>
                                                            <v-list-item-title v-if="!hasRole(rolle.id)"
                                                                >Legg til som {{ rolle.navn.toLowerCase() }}
                                                            </v-list-item-title>
                                                            <v-list-item-title v-else> Fjern som {{ rolle.navn.toLowerCase() }} </v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="10">
                                    <v-text-field dense v-model="tjenesteStreng" readonly label="Tjenester">
                                        <template v-slot:append-outer>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined v-on="on" style="margin-top: -10px">Endre </v-btn>
                                                </template>
                                                <v-list>
                                                    <template v-for="(rolle, i) in tjenesteListe">
                                                        <v-list-item :key="i" v-on:click="changeRole(rolle.id)" link>
                                                            <v-list-item-title v-if="!hasRole(rolle.id)"
                                                                >Legg til i {{ rolle.navn.toLowerCase() }}
                                                            </v-list-item-title>
                                                            <v-list-item-title v-else> Fjern i {{ rolle.navn.toLowerCase() }} </v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn v-if="person.userId == null" v-on:click="createLogin" outlined>Opprett konto</v-btn>
                                    <v-chip v-else close v-on:click:close="deleteLogin">Brukernavn: {{ person.epost }}</v-chip>
                                </v-col>
                                <v-col cols="12">
                                    <status-field v-model="person" v-on:updated="updated"></status-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu v-if="person.samtykke && person.samtykke.samtykket && person.status == 'SLUTTET'" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined v-bind="attrs" v-on="on">
                                                Slett
                                                <v-icon right dark> mdi-menu-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link v-on:click="deleteUser(true)">
                                                <v-list-item-title>Slett og ta vare på kontaktinfomaskjon</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item link v-on:click="deleteUser(false)">
                                                <v-list-item-title>Slett</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-btn v-else-if="person.status == 'SLUTTET'" v-on:click="deleteUser(false)" outlined>Slett</v-btn>
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col cols="10"> <v-text-field v-model="person.epost" readonly label="Epost" hide-details></v-text-field> </v-col>
                                <v-col cols="10"> <v-text-field v-model="person.mobil" readonly label="Mobil" hide-details></v-text-field> </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileImage from '@/components/ProfileImage.vue';
import StatusField from '@/pages/persons/StatusField';

export default {
    name: '',
    components: {
        ProfileImage,
        StatusField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            person: {},

            rolleStreng: '',
            tjenesteStreng: '',

            tjenesteListe: [],
        };
    },

    computed: {
        ...mapGetters('api', ['hasUserRole', 'roller', 'rolle']),
    },

    watch: {
        value: async function (value) {
            this.person = this.value = value;
            await this.update();
        },
    },

    /**
     * created
     */
    created: async function () {
        if (this.value) {
            this.person = this.value;
            await this.update();
        }
    },
    methods: {
        ...mapActions(['alert', 'confirm', 'snackbar']),
        ...mapActions('api', ['request', 'formatPerson']),

        /**
         * update
         */
        update: async function (person) {
            if (person) {
                this.person = await this.formatPerson(person);
                this.$emit('updated', this.person);
            }

            // roller
            const roller = [];
            for (let rolle of this.person.roller) {
                rolle = this.rolle(rolle.id);
                if (!rolle.foreldre) {
                    roller.push(roller.length ? rolle.navn.toLowerCase() : rolle.navn);
                }
            }
            this.rolleStreng = roller.join(', ');

            // tjenester
            const tjenester = [];
            for (let rolle of this.person.roller) {
                rolle = this.rolle(rolle.id);
                if (rolle.foreldre) {
                    if (roller.length > 1) {
                        tjenester.push(tjenester.length ? rolle.navn.toLowerCase() : rolle.navn);
                    } else {
                        tjenester.push(tjenester.length ? rolle.kortNavn.toLowerCase() : rolle.kortNavn);
                    }
                }
            }
            this.tjenesteStreng = tjenester.join(', ');

            const tjenesteListe = [];
            for (let rolle of this.roller) {
                if (rolle.foreldre && this.hasRole(rolle.foreldre)) {
                    rolle = Object.assign({}, rolle);
                    if (roller.length == 1) {
                        rolle.navn = rolle.kortNavn;
                    }
                    tjenesteListe.push(rolle);
                }
            }
            this.tjenesteListe = tjenesteListe;
        },

        /**
         * updated
         */
        updated: function (person) {
            this.person = person;
            this.update();
            this.$emit('updated', this.person);
        },

        /**
         * changeRole
         */
        changeRole: async function (rolle) {
            let response = {};
            if (!this.hasRole(rolle)) {
                response = await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + '/rolle/' + rolle,
                });
            } else {
                response = await this.request({
                    method: 'delete',
                    url: '/person/' + this.person.id + '/rolle/' + rolle,
                });
            }
            if (response && typeof response.id != 'undefined') {
                await this.update(response);
            }
        },

        /**
         * hasRole
         */
        hasRole: function (rolle) {
            return this.person.roller && this.person.roller.filter((r) => r.id == rolle).length > 0;
        },

        /**
         * createLogin
         */
        createLogin: async function () {
            if (!this.person.mobil || !this.person.mobil.trim()) {
                await this.alert({
                    title: 'Feil',
                    message: 'Mangler mobil.<br><br>Personen ha et mobilnummer for å kunne opprette en innloggong. Dette er på grunn av 2-faktor.',
                });
                return;
            }

            const response = await this.request({
                method: 'post',
                url: '/person/' + this.person.id + '/aktiverLogin/',
            });
            if (response && response.id) {
                this.snackbar('Brukerinnlogging opprettet.');
                this.update(response);
            }
        },

        /**
         * deleteLogin
         */
        deleteLogin: async function () {
            if (await this.confirm({ title: 'Slette innlogging', message: 'Er du sikker på at du vil slette innlogging for ' + this.person.fulltNavn + '?' })) {
                const response = await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + '/deaktiverLogin/',
                });
                if (response && response.id) {
                    this.snackbar('Innlogging slettet.');
                    this.update(response);
                }
            }
        },

        /**
         * deleteUser
         */
        deleteUser: async function (archive) {
            if (
                await this.confirm({
                    title: 'Slette person',
                    message:
                        'Er du sikker på at du vil slette personen ' +
                        this.person.fulltNavn +
                        '?<br><br>' +
                        (archive ? 'Kontaktinformasjon vil bli lagret.' : 'All informasjon vil bli slettet.'),
                })
            ) {
                const response = await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + (archive ? '/arkiver/' : '/slett/'),
                });

                if (response && response.id) {
                    this.snackbar('Person slettet!');
                    this.$emit('close');
                }
            }
        },
    },
};
</script>
