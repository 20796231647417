<template>
    <div>
        <v-row>
            <v-col cols="12" sm="10" class="d-flex align-center">
                <v-text-field v-model="status" label="Status" readonly></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="5" class="d-flex align-center">
                <date-field v-model="oppstartsdato" label="Oppstart" max-width="150px"></date-field>
            </v-col>
            <v-col cols="12" sm="7" class="d-flex align-center">
                <date-field v-model="sluttet" label="Sluttet" max-width="150px"></date-field>
                <v-btn v-if="endretOppstart || endretSluttet" v-on:click="saveDates" outlined class="ml-4">Lagre</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-row v-for="permisjon in person.permisjoner" v-bind:key="permisjon.id">
                    <v-col cols="12" sm="5" class="d-flex align-center">
                        <date-field v-model="permisjon.fra" label="Permisjon fra" max-width="150px"></date-field>
                    </v-col>
                    <v-col cols="12" sm="7" class="d-flex align-center">
                        <date-field v-model="permisjon.til" label="Permisjon til" max-width="150px"></date-field>
                        <v-btn v-on:click="deleteLeave(permisjon)" icon class="ml-4">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="person.status != 'SLUTTET' && !newLeave" cols="12" sm="3" class="d-flex align-center">
                    <v-col cols="12">
                        <v-btn v-on:click="newLeave = true" outlined>Legg til permisjon</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="newLeave">
                    <v-col cols="12" sm="5" class="d-flex align-center">
                        <date-field v-model="permisonFra" label="Fra" max-width="150px"></date-field>
                    </v-col>
                    <v-col cols="12" sm="7" class="d-flex align-center">
                        <date-field v-model="permisonTil" label="Til" max-width="150px"></date-field>
                        <v-btn v-on:click="addLeave" outlined class="ml-4">Lagre</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: 'StatusField',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            person: {},
            //status: '',
            oppstartsdato: null,
            sluttet: null,

            newLeave: false,

            permisonFra: null,
            permisonTil: null,

            statuses: [
                { status: 'AKTIV', label: 'Aktiv' },
                { status: 'PERMISJON', label: 'I permisjon' },
                { status: 'SLUTTET', label: 'Sluttet' },
            ],
        };
    },
    watch: {
        value: async function (value) {
            this.person = this.value = value;
            await this.update();
        },
    },
    computed: {
        endretOppstart: function () {
            return this.oppstartsdato != this.person.oppstartsdato;
        },
        endretSluttet: function () {
            return this.sluttet != this.person.sluttet;
        },
        status: function () {
            switch (this.person.status) {
                case 'SLUTTET':
                    return 'Sluttet';
                case 'PERMISJON':
                    return 'I permisjon';
                case 'AKTIV':
                    return 'Aktiv';
            }
            return '';
        },
    },

    /**
     * created
     */
    created: function () {
        if (this.value) {
            this.person = this.value;
            this.update();
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar', 'alert']),

        /**
         * update
         */
        update: function () {
            if (this.person) {
                this.sluttet = this.person.sluttet;
                this.oppstartsdato = this.person.oppstartsdato;
            }
        },

        /**
         * saveDates
         */
        saveDates: async function () {
            let person = {};
            if (this.endretOppstart) {
                person = await this.startDate();
            }
            if (this.endretSluttet) {
                person = await this.terminate();
            }

            if (person && typeof person.id != 'undefined') {
                this.person = await this.formatPerson(person);
                this.update();
                this.$emit('updated', this.person);
                this.snackbar('Lagret');
            }
        },

        /**
         * start
         */
        startDate: async function () {
            if (this.endretOppstart) {
                const data = {};
                for (const field of ['fornavn', 'etternavn', 'kjoenn']) {
                    data[field] = this.person[field];
                }
                for (const field of ['foedselsdato', 'oppstartsdato', 'soknadsdato', 'politiattest', 'taushetserklaering']) {
                    data[field] = this.person[field] ? this.person[field].format('DD.MM.YYYY') : null;
                }

                data.oppstartsdato = this.oppstartsdato ? this.oppstartsdato.format('DD.MM.YYYY') : null;

                return await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + '/personalia',
                    data: data,
                });
            }
        },

        /**
         * terminate
         */
        terminate: async function () {
            if (this.sluttet) {
                const params = {
                    sluttet: this.sluttet ? this.sluttet.format('DD.MM.YYYY') : null,
                };

                return await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + '/sluttet',
                    params: params,
                });
            } else {
                return await this.request({
                    method: 'delete',
                    url: '/person/' + this.person.id + '/sluttet',
                });
            }
        },

        /**
         * leave
         */
        addLeave: async function () {
            if (this.permisonFra && this.permisonTil && this.permisonFra.isBefore(this.permisonTil)) {
                const params = {
                    fra: this.permisonFra.format('DD.MM.YYYY'),
                    til: this.permisonTil.format('DD.MM.YYYY'),
                };

                const response = await this.request({
                    method: 'post',
                    url: '/person/' + this.person.id + '/permisjon',
                    params: params,
                });

                if (response && typeof response.id != 'undefined') {
                    this.newLeave = false;
                    this.permisonFra = null;
                    this.permisonTil = null;
                    this.person = await this.formatPerson(response);
                    this.update();
                    this.$emit('updated', this.person);
                    this.snackbar('Permisjon lagt til');
                }
            } else {
                await this.alert({
                    title: 'Feil',
                    message: 'Fra og til må begge oppgis og sluttdato må være etter startdato.',
                });
            }
        },

        /**
         * deleteLeave
         */
        deleteLeave: async function (permisjon) {
            const response = await this.request({
                method: 'delete',
                url: '/person/' + this.person.id + '/permisjon/' + permisjon.id,
            });

            if (response && typeof response.id != 'undefined') {
                this.person = await this.formatPerson(response);
                this.update();
                this.$emit('updated', this.person);
                this.snackbar('Permisjon fjernet');
            }
        },
    },
};
</script>
<style lang="scss"></style>
